@use "mixins" as *
.oddsDropdown
  > div:first-of-type
    min-width: 98px
    border: 0
    color: rgb(var(--primaryColor))
    font-size: 11px
    padding: 4px 8px
    min-height: 27px

    > span
      padding-right: 8px
      @include flex

      > i
        margin-right: 8px

    &:after
      background-color: rgb(var(--secondaryColor))
      margin-left: auto
      opacity: 0.7
      margin-top: -1px

    &:hover:after
      opacity: 1

  &:hover > div:first-of-type
    color: rgba(var(--secondaryColor), 0.9)

    &:after
      border-color: rgba(var(--secondaryColor), 0.8) transparent transparent transparent

  > section
    background: var(--customDdl_bg)
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5), -1px 1px 4px rgba(0, 0, 0, 0.5)
    max-height: unset

    > div
      max-height: unset

    > div:last-of-type > div
      background: var(--customDdl_bg)

    ul > li
      padding: 0 8px
      height: 26px

      > i
        margin-right: 8px
