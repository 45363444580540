@use "mixins" as *
.footer
    background: var(--footer_bg)
    display: flex
    flex-direction: column
    border-top: 2px solid rgb(var(--thirdaryColor))
    padding: 0 14px 20px

.item
    border-bottom: 1px solid var(--footer_border)
    @include bold
    font-size: 16px
    overflow: hidden
    > div
        background: var(--footer_bg)
        min-height: 40px
        color: rgb(var(--secondaryColor))
        @include flex
        text-transform: uppercase
        position: relative
        z-index: 1

        &.opened .arrow
            transform: rotate(180deg)
            path
                fill: rgb(var(--secondaryColor))

    > nav > *
        @include flex
        min-height: 24px
        color: #B1B5C3
        margin-bottom: 8px

.arrow
    transform: rotate(0deg)
    transition: all 0.2s
    min-width: 10px
    height: 5px
    margin-left: auto
    margin-top: -1px
    @include flex
    > span
        @include flex
    path
        fill: #696C7B

.txt
    color: rgb(var(--base_textColor), .8)
    font-size: 14px
    line-height: 20px
    padding: 8px 0
    border-bottom: 1px solid var(--footer_border)

.oddsFormatSelectorContainer
    @include flex
    justify-content: space-between
    padding: 10px 0
    a
        display: block
        text-align: center
        color: #696C7B
        font-size: 16px
        @include bold

        &:hover
            color: rgb(var(--secondaryColor))

        &:active
            opacity: 0.8

    > div
        background: var(--footer_ddlBg)
        width: 92px
        border-radius: var(--base_borderRadius)

        > div:first-of-type
            padding: 8px
            min-width: auto
            min-height: 38px
            font-size: var(--button_fontSize)!important

.socialLinks
    @include flex
    justify-content: center
    padding: 8px
    border-top: 1px solid var(--footer_border)
    border-bottom: 1px solid var(--footer_border)
    svg path
        fill: #E6E8EC

.footerLogo
    border-bottom: 1px solid var(--footer_border)
    box-shadow: 0 1px var(--footer_shadow)
    @include flex
    justify-content: center
    flex-direction: column
    padding: 12px 10px

    h1
        margin-top: 10px
        color: rgb(var(--secondaryColor))
        text-align: center

.footerSite
    margin-top: 10px
    margin-bottom: 6px

.footerSite, .version
    color: rgb(var(--base_textColor), 0.5)
    font-size: var(--button_fontSize)
