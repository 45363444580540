@use "mixins" as *
.footer
  background: var(--footer_bg)
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.3)
  border-top: 2px solid rgb(var(--particularColor))
  @include flex
  position: relative
  z-index: 10
  flex-direction: column
  min-height: 498px
  margin-top: 30px

  > div
    max-width: 880px
    width: 100%
    @include flex
    justify-content: center

    &.footerLinks
      align-items: flex-start
      flex-wrap: wrap
      padding: 8px 12px

      > nav
        flex: 1
        @include flex
        flex-direction: column
        align-items: center

        a
          display: block
          margin-bottom: 12px
          text-align: center

          &:hover
            color: rgb(var(--secondaryColor))

          &:active
            opacity: 0.8

        > div
          background: var(--footer_ddlBg)
          width: 92px
          border-radius: var(--base_borderRadius)
          margin-bottom: 14px
          margin-top: -4px

          > div:first-of-type
            padding: 8px
            min-width: auto

      h2
        color: rgb(var(--secondaryColor))
        @include bold
        margin: 16px 0

  .websiteTitle
    opacity: 0.9
    padding: 10px 0 6px 0

  :global(.svg-icon svg path)
    fill: rgb(var(--primaryColor))

.footerLogo
  border-bottom: 1px solid var(--footer_border)
  box-shadow: 0 1px var(--footer_shadow)
  justify-content: center
  flex-direction: column
  padding: 12px 10px

  h1
    margin-top: 10px
    color: rgb(var(--secondaryColor))
    text-align: center

.socialLinksContainer
  height: 50px
  @include flex
  justify-content: space-between
  width: 100%
  max-width: 600px
  padding: 0 20px
  border-bottom: 1px solid var(--footer_border)
  box-shadow: 0 1px var(--footer_shadow)

  .age
    margin-right: 10px

  > nav
    @include flex

.age
  width: 30px
  height: 30px
  @include flex
  justify-content: center
  background: var(--footer_socialLinkBg)
  border-radius: 100%
  color: rgb(var(--primaryColor))
  font-size: 15px

.licenseLogo > *
  margin: 6px 10px -4px

.footerText
  padding: 10px 28px 8px
  border-bottom: 1px solid var(--footer_border)
  box-shadow: 0 1px var(--footer_shadow)
  line-height: 16px
  color: rgb(var(--base_textColor), .8)
  display: block!important

@media screen and (max-width: 1700px)
  .footerLinks nav
    min-width: calc(100% / 3)

  .footerCurrencyLogos > div
    min-width: 50%

.footerCurrencyLogos
  border-bottom: 1px solid var(--footer_border)
  box-shadow: 0 1px var(--footer_shadow), inset 0 1px var(--footer_shadow)
  border-top: 1px solid var(--footer_border)
  justify-content: space-around !important
  flex-wrap: wrap
  margin-top: 10px
  min-height: 50px
  padding: 10px

  > span
    margin: 0 10px !important

  @media screen and (max-width: 1760px)
    padding: 10px 20px
    justify-content: space-around
    > span
      margin: 0 14px !important
      text-align: center

.beGambleAware
  padding: 10px
  border-bottom: 1px solid var(--footer_border)
  box-shadow: 0 1px var(--footer_shadow)
  @include flex
  flex-direction: column
  justify-content: center
  .age
    margin-top: 10px
    border: 2px solid #CCEF57
    background-color: transparent
    color: rgb(var(--secondaryColor))

.version
  padding-bottom: 10px
  text-align: center
  font-size: 11px
  color: var(--footer_versionColor)
