@use "mixins" as *
.searchContainer
  position: relative

.searchField
  width: 30px
  overflow: hidden
  transition: width 0.3s, background 0.5s
  border-radius: 4px
  position: absolute
  right: 0

  &:hover:not(.open)
    @include pointer

  &.open
    width: 230px
    background: var(--casinoCompact_searchBg)
    transition: width 0.3s, background 0.2s

    .closeSearch
      opacity: 0.7
      transition: opacity 0.4s
      visibility: unset
      pointer-events: unset

      &:hover
        opacity: 1
        @include pointer
    .searchIcon:before
      background: var(--casinoCompact_searchActiveIconBg)

  > div input
    border: 0
    padding: 6px 24px 6px 32px
    position: relative
    height: 26px
    color: rgb(var(--secondaryColor))

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active
      box-shadow: inset 0 0 0 50px var(--casinoCompact_searchBg)
      -webkit-text-fill-color: rgb(var(--secondaryColor))
      border-bottom: 0
      color: rgb(var(--secondaryColor))

    &::placeholder
      color: rgba(var(--secondaryColor), 0.8)

.closeSearch
  background: var(--icon-close) no-repeat
  width: 8px
  height: 8px
  position: absolute
  right: 8px
  z-index: 2
  top: 50%
  transform: translateY(-50%)
  opacity: 0
  visibility: hidden
  pointer-events: none

.searchIcon
  position: absolute
  width: 32px
  height: 100%
  left: 0
  top: 0
  @include flex
  justify-content: center
  z-index: 1

  &:before
    content: ""
    mask-image: var(--icon-casino-compact-search)
    background: var(--casinoCompact_searchIconBg)
    width: 16px
    height: 16px
