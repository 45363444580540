@use "mixins" as *
.loggedIn
  background: var(--header_loginPanelBg)
  padding: var(--header_loginPanelPadding)
  border-radius: 2px
  box-shadow: var(--header_loginPanelShadow)
  position: relative
  @include flex
  font-size: var(--base_fontSize)
  color: rgb(var(--secondaryColor))

  nav
    @include flex

    .referralBtn,
    .messagesBtn,
    .myBetsBtn,
    .balance,
    .depositBtn
      margin-left: 20px
      position: relative

      &:after
        content: ""
        height: 15px
        border-right: var(--header_loginPanelSeparator)
        position: absolute
        left: -10px

    .balance
      margin-right: 0

    .depositBtn
      min-width: 84px

    .referralBtn,
    .messagesBtn,
    .myBetsBtn
      @include flex
      min-width: 16px
      > span
        @include flex

      svg
        opacity: 0.7
        :global(.account-menu-item-bg)
          fill: rgb(var(--primaryColor))

      &:hover
        @include pointer

        svg
          opacity: 1

      &::after
        margin-top: 1px

    .referralBtn
      padding: 0
      height: unset
      background: unset

  :global(.user-panel-links)
    @include flex

.username
  color: rgba(var(--header_userNameColor), 0.9)
  white-space: nowrap
  line-height: var(--header_userNameHeight)
  min-height: var(--header_userNameHeight)
  background: var(--header_userNameBg)
  border-radius: var(--header_userNameRadius)
  padding: var(--header_userNamePadding)
  position: relative

  .userIcon
    background: var(--header_userIconBg)
    width: var(--header_userIconSize)
    height: var(--header_userIconSize)
    box-shadow: inset 0 0 0 1px var(--header_userIconShadow)
    border-radius: 100%
    left: var(--header_userIconLeftPosition)
    top: calc((var(--header_userNameHeight) - var(--header_userIconSize)) / 2)
    position: absolute
    @include flex
    justify-content: center
    > span
      @include flex
    svg
      opacity: 0.8

  &:hover
    color: rgb(var(--header_userNameColor))

    .userIcon svg
      opacity: 1

.verificationReminder
  position: absolute
  background: rgb(var(--secondaryColor))
  width: 16px
  height: 16px
  min-width: unset
  border-radius: 100%
  left: var(--verificationReminderLeft)
  bottom: var(--verificationReminderBottom)
  @include flex
  justify-content: center
  span
    @include flex

.balance
  background: var(--header_balanceBg)
  display: inline-flex
  align-items: center
  padding: 2px 6px
  min-height: var(--header_balanceMinHeight)
  vertical-align: top
  border-radius: var(--base_borderRadius)
  min-width: var(--header_balanceMinWidth)
  position: relative
  margin-right: 10px

  .bonusLabel
    position: absolute
    left: 26px
    top: 0

  > div > .currentWalletCurrency
    margin: 0 2px 0 8px

    > span
      color: rgb(var(--secondaryColor))

      > span
        font-size: 11px

  > div
    color: rgb(var(--secondaryColor))
    @include flex
    flex: 1

    > .typeBonus
      margin-left: 10px
      margin-top: 4px

    &:before
      content: ""
      width: 0
      height: 0
      border-style: solid
      border-width: 7px 0 0 7px
      border-color: transparent transparent transparent rgba(var(--header_balanceTriangle), 0.6)
      position: absolute
      left: 26px
      bottom: 0

    &:hover
      @include pointer

      &:before
        border-color: transparent transparent transparent rgb(var(--header_balanceTriangle))

.balanceDropdown
  position: absolute
  background: var(--customDdl_bg)
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5), -1px 1px 4px rgba(0, 0, 0, 0.5)
  width: 100%
  top: calc(var(--header_balanceMinHeight) + 4px)
  left: 0
  border-radius: var(--base_borderRadius)
  z-index: 100
  overflow: hidden

  li
    @include flex
    padding: 0 8px
    height: 34px
    background: var(--header_balanceDdlBg)
    border-top: 1px solid var(--header_balanceDdlBorderTop)
    border-bottom: 1px solid var(--header_balanceDdlBorderBottom)
    position: relative

    .bonusLabel
      left: 0

    > .typeBonus
      margin-top: 6px

    &:hover:not(.selected)
      background: rgba(var(--header_balanceDdlSelectedBg), 0.5)
      border-top-color: rgba(var(--header_balanceDdlSelectedBorderTop), 0.8)

    &:first-of-type
      border-top: 0
      height: 33px

    &:last-of-type
      border-bottom: 0
      height: 33px

    &.selected
      background: rgb(var(--header_balanceDdlSelectedBg))
      border-top-color: rgb(var(--header_balanceDdlSelectedBorderTop))
      cursor: default

      .currentWalletCurrency > span
        color: rgb(var(--secondaryColor))

.currentWalletCurrency
  display: flex
  flex-direction: column
  margin-left: 10px
  flex: 1
  color: rgb(var(--primaryColor))
  font-size: 11px

  > span
    color: #d2d3d4
    @include flex
    justify-content: space-between
    line-height: 10px
    margin: 3px 0

    > span
      margin-left: 10px
      font-size: 12px

.currency
  @include flex
  justify-content: space-between
  font-size: 10px
  line-height: 10px

  > span:not(.bonusWallet):last-of-type
    @include flex
    margin-left: 10px
    height: 100%

    > i
      margin-right: 4px
      font-style: unset
      font-size: 12px

    :global(.loader)
      position: static

      > span
        font-size: 5px

.bonusWallet
  background: #171b20
  color: rgb(255, 216, 1)
  padding: 2px 4px 1px
  margin-left: -4px
  border-radius: 2px
  display: inline-block

.showBalance,
.hideBalance
  opacity: 0.8
  margin-right: 6px
  position: relative
  width: 13px
  background-color: rgb(var(--secondaryColor))

  &:hover
    @include pointer
    opacity: 1

.showBalance
  height: 8px
  mask-image: var(--icon-password-show)

.hideBalance
  height: 10px
  mask-image: var(--icon-password-hide)

.separator
  content: ""
  height: 15px
  border-right: var(--header_loginPanelSeparator)
  margin-right: 6px

.depositBtn
  @include flex
  height: var(--header_depositBtnHeight)
  color: var(--header_depositBtnColor)
  font-size: var(--base_fontSize)
  position: relative
  justify-content: center
  padding: var(--header_depositBtnPadding)
  @include bold
  text-transform: var(--base_textTransform)
  background: var(--header_depositBtnBg)

  .depositIcon
    @include flex
    justify-content: center
    border-left: 1px solid var(--header_depositBtnBorder)
    box-shadow: inset 1px 0 var(--header_depositBtnShadow)
    padding: var(--header_depositBtnIconPadding)
    height: 100%
    position: absolute
    right: 0
    svg
      width: auto!important
      height: var(--header_depositBtnIconHeight)!important
      @include flex
      path
        fill: var(--header_depositBtnColor)

  &:hover:not(:active)
    background: var(--header_depositBtnHoverBg)

  &:active
    background: var(--header_depositBtnActiveBg)

  &:after
    margin-top: 1px

.messages
  position: relative

.msgCount
  min-width: var(--header_msgCountSize)
  height: var(--header_msgCountSize)
  border-radius: 100%
  color: #000
  background: var(--header_msgCountBg)
  position: absolute
  bottom: var(--header_msgCountPosition)
  right: calc(var(--header_msgCountSize) / 2 * -1)
  font-size: var(--header_msgCountFontSize)
  padding-top: 2px
  text-align: center
  box-shadow: -2px 2px 1px rgba(0, 0, 0, 0.25)

.exitBtn
  min-width: var(--header_exitBtnWidth)
  margin-left: 8px
  height: var(--header_btnHeight)
  text-transform: var(--base_textTransform)
  background: var(--header_exitBtnBg)
  color: var(--loginBtn_color)
  &:hover
    background: var(--header_exitBtnHoverBg)
  &:active
    background: var(--header_exitBtnActiveBg)

.userPanelLoader
  width: 350px !important
  position: static !important
  margin-left: auto

  > span
    font-size: 16px !important

.balanceLoader
  left: 0

  > span
    font-size: 8px !important

//modals
.accountModal :global(.modal)
  width: 890px
  height: 680px
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.3)
  background: var(--account_bg)
  border-radius: 17px

  // overflow: hidden   date calendar

  .accountModalLoader span
    font-size: 26px
    width: 26px
    height: 26px

.walletModal :global(.modal)
  border-radius: 6px
  overflow: hidden
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5)
  width: 626px
  min-height: 420px
  background: var(--wallet_bg)

  .walletModalLoader span
    font-size: 26px
    width: 26px
    height: 26px

.walletPixModal :global(.modal), .multiWalletModal :global(.modal)
  width: 90%
  max-width: 400px
  border-radius: 8px
  background: var(--walletPix_modalBg)
  height: 86%
  max-height: 600px
  font-family: var(--roboto-font)

  ::-webkit-scrollbar
    width: 10px

  ::-webkit-scrollbar-thumb
    background: var(--walletPix_amountBtnBg)
    border-radius: 10px
    border: 3px solid var(--walletPix_modalBg)

  > i
    width: 28px
    height: 28px
    border-radius: 8px
    background: var(--walletPix_tabBg)
    top: 6px
    right: 6px
    &:before
      mask-size: 14px
      width: 14px
      height: 14px

  .walletModalLoader span
    font-size: 26px
    width: 26px
    height: 26px

.walletPixModal :global(.modal)

.walletTolaModal :global(.modal)
  width: 90%
  max-width: 400px
  border-radius: 8px
  background: var(--walletPix_modalBg)
  max-height: 560px
  height: 86%
  font-family: var(--roboto-font)

  > i
    width: 28px
    height: 28px
    border-radius: 8px
    background: var(--walletPix_fieldBg)
    top: 6px
    right: 6px
    &:before
      mask-size: 14px
      width: 14px
      height: 14px

.verifyModal :global(.modal) > i, .userVerificationModal :global(.modal) > i
  border-top-right-radius: 6px
  width: 23px
  height: 24px
  padding-left: 2px
  padding-bottom: 2px
  background: var(--login_closeBtnBg)
